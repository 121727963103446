import * as React from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import Navbar from "react-bootstrap/Navbar";
import { Container } from "react-bootstrap";
import logo from "../navbar/logo.png";

const NavbarLink = ({ page, selected }) => {
  const title = (page.charAt(0).toUpperCase() + page.slice(1)) as string;

  return (
    <NavLink style={{ color: "black" }} to={`/${page}`}>
      {title}
    </NavLink>
  );
};

export function NavBar() {
  const page = useParams().page || "dashboard";

  return (
    <>
      <EaNavbar bg='light' variant='dark'>
        <Container>
          <a
            href='/'
            style={{
              padding: "0.1875em",
            }}
          >
            <img src={logo} width='130' height='100' alt='EA-Portal-1' />
          </a>

          <NavbarDiv className='header'>
            <a
              style={{
                textDecoration: "none",
                color: "rgb(22, 83, 203)",
                margin: "0px 10px",
                fontWeight: 800,
                cursor: "pointer",
                letterSpacing: "2px",
              }}
              href='#info'
            >
              About EA1
            </a>
            <a
              style={{
                textDecoration: "none",
                color: "rgb(22, 83, 203)",
                margin: "0px 10px",
                fontWeight: 800,
                cursor: "pointer",
                letterSpacing: "2px",
              }}
              href='#contact-us'
              id='contact-us-button'
            >
              Contact Us
            </a>
          </NavbarDiv>
        </Container>
      </EaNavbar>
    </>
  );
}

// Styled Components
const NavbarDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const NavLink = styled(Link)`
  color: #f4f4f4;
  font-weight: 900;
  text-decoration: none;
  padding: 0 5px;
`;

const EaNavbar = styled(Navbar)`
  overflow: hidden;
  z-index: 1;
  position: fixed;
  top: 0;
  width: 100%;
`;
